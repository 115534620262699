import React from "react";
import Img from "gatsby-image";

const FullImage = ({ imgSrc, imgAlt = `Full width image`, imgStyle }) => {
  return (
    <section className="full-image w-full relative">
      <Img
        className="w-full h-full relative block object-cover"
        fluid={imgSrc}
        alt={imgAlt}
        imgStyle={imgStyle}
      />
    </section>
  );
};

export default FullImage;
