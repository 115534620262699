import React, { useContext } from "react";
import Carousel from "~components/Carousel";
import { DocumentContext } from "~context/DocumentContext";

const ProductFeatures = ({ heading, features }) => {
  const { device } = useContext(DocumentContext);

  return (
    <div className="w-full relative bg-off-white">
      <section>
        {heading && (
          <div className="text-center mt-v8 mb-v10 px-20 xs:px-5 w-full">
            <h2 className="mt-v05 f2 xs:f2"> {heading} </h2>
          </div>
        )}
        {device !== `mobile` && features && (
          <div className="grid-end-12 mt-v3 mb-v5 grid">
            {features.map((feature, index) => (
              <div
                className="text-left flex flex-col items-center gap-5 grid-end-4 grid-end-4"
                key={`product-feature-${index}`}
              >
                <img
                  src={feature.icon.asset.url}
                  alt="icon"
                  className="max-w-xs"
                />
                <p className="b2 max-w-xs mt-v1">{feature.text}</p>
              </div>
            ))}
          </div>
        )}

        {device === `mobile` && features && (
          <div className="px-5 my-20">
            <Carousel
              items={features.map((feature, index) => {
                const key = `product-feature-${index}`;

                return (
                  <div
                    className="w-full relative text-left flex flex-col items-center"
                    key={key}
                  >
                    <img src={feature.icon.asset.url} alt="icon" className="" />
                    <p className="b2 mt-10 w-4/5 text-center">{feature.text}</p>
                  </div>
                );
              })}
              withBullets
            />
          </div>
        )}
      </section>
    </div>
  );
};

export default ProductFeatures;
