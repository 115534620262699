/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useContext } from "react";
import { graphql } from "gatsby";
import FullImage from "~components/FullImage";
import { DocumentContext } from "~context/DocumentContext";
import ProductOverview from "~components/ProductOverview";
import ProductFeatures from "~components/ProductFeatures";
import GivePaireCTA from "~components/GivePaireCTA";
import ProductGrid from "~components/ProductGrid";
import Footer from "~components/Footer";
import Layout from "~components/Layout";
import SEO from "~components/SEO";

const AboutPage = ({ data, location }) => {
  const { device } = useContext(DocumentContext);

  const { sanityAboutPage } = data;

  return (
    <>
      <SEO
        customTitle={sanityAboutPage.title}
        customDescription="About page"
        customKeywords="About Page"
        path={location.pathname}
      />

      <Layout className="about-page w-full relative">
        {device !== `desktop` ? (
          <FullImage
            imgSrc={sanityAboutPage.heroImage.asset.fluid}
            imgAlt={sanityAboutPage.heroImage.altText}
            imgStyle={{ objectPosition: `30% center` }}
          />
        ) : (
          <FullImage
            imgSrc={sanityAboutPage.heroImage.asset.fluid}
            imgAlt={sanityAboutPage.heroImage.altText}
            imgStyle={{ height: `150%`, objectPosition: `50% center` }}
          />
        )}

        <ProductOverview
          image={sanityAboutPage.goodFeels.image.asset.fluid}
          imageAlt={sanityAboutPage.goodFeels.image.altText}
          heading={sanityAboutPage.goodFeels.heading}
          text={sanityAboutPage.goodFeels.text}
          position={sanityAboutPage.goodFeels.position ? `right` : `left`}
          backgroundColor={sanityAboutPage.goodFeels.backgroundColour}
        />

        <ProductOverview
          image={sanityAboutPage.socialGoodness.image.asset.fluid}
          imageAlt={sanityAboutPage.socialGoodness.image.altText}
          heading={sanityAboutPage.socialGoodness.heading}
          text={sanityAboutPage.socialGoodness.text}
          position={sanityAboutPage.socialGoodness.position ? `right` : `left`}
          backgroundColor={sanityAboutPage.socialGoodness.backgroundColour}
        />

        <ProductFeatures
          heading={sanityAboutPage.natureBlock.heading}
          features={sanityAboutPage.natureBlock.features}
        />

        {sanityAboutPage.productOverviewBlocks.map((product, index) => {
          const key = `product-overview-${index}`;

          return (
            <ProductOverview
              key={key}
              image={product.image.asset.fluid}
              imageAlt={product.image.altText}
              heading={product.heading}
              subheading={product.subHeading}
              text={product.text}
              position={product.position ? `right` : `left`}
              backgroundColor={product.backgroundColour}
            />
          );
        })}

        <ProductGrid max={3} />

        <GivePaireCTA />
      </Layout>

      <Footer />
    </>
  );
};

export default AboutPage;

export const query = graphql`
  query AboutPage {
    sanityAboutPage {
      title
      heroImage {
        asset {
          fluid(maxWidth: 1600) {
            ...GatsbySanityImageFluid
          }
        }
        altText
      }
      goodFeels {
        heading
        text
        image {
          asset {
            fluid(maxWidth: 768) {
              ...GatsbySanityImageFluid
            }
          }
          altText
        }
        position
        backgroundColour
      }
      socialGoodness {
        heading
        text
        image {
          asset {
            fluid(maxWidth: 768) {
              ...GatsbySanityImageFluid
            }
          }
          altText
        }
        position
        backgroundColour
      }
      natureBlock {
        heading
        features {
          name
          icon {
            asset {
              url
            }
            altText
          }
          text
        }
      }
      productOverviewBlocks {
        subHeading
        heading
        text
        image {
          asset {
            fluid(maxWidth: 768) {
              ...GatsbySanityImageFluid
            }
          }
          altText
        }
        position
        backgroundColour
      }
    }
    allSanityProduct {
      edges {
        node {
          title
          frontPage
          tagline
          image {
            asset {
              fluid(maxWidth: 1024) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    allShopifyProduct {
      edges {
        node {
          id
          title
          description
          handle
          images {
            originalSrc
          }
          productType
          vendor
          variants {
            id
            title
            image {
              originalSrc
            }
            price
            selectedOptions {
              name
              value
            }
          }
        }
      }
    }
    allShopifyAdminProduct {
      edges {
        node {
          products {
            handle
            variants {
              alternative_id
            }
          }
        }
      }
    }
  }
`;
